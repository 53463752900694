import React from 'react';
import styled from 'styled-components';
import { Typography } from '@deeptrue-frontend/ui';
import Title from '../Base/Title';
import Section from '../Base/Section';

const Box = styled.div`
  width: 100%;

  @media (min-width: 960px) {
    width: 50%;
  }
`;

const BoxLeft = styled(Box)`
  margin-bottom: 1.5rem;
  @media (min-width: 960px) {
    padding-right: 5rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;
`;

const Paragraphs = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const MainParagraph = styled(Typography)`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
`;

const StyledSection = styled(Section)`
  @media (min-width: 960px) {
    margin-top: 4rem;
  }
`;

const StyledTitle = styled(Title)`
  h6 {
    text-align: left;
  }
  @media (min-width: 960px) {
    h6 {
      &::after {
        margin-left: 2rem;
      }
    }
  }
`;

interface Props {
  tag: string;
  title: string;
  mainDescription: string;
  descriptions: Array<{
    description_paragraph: string;
  }>;
}

const Description = ({ tag, title, mainDescription, descriptions }: Props) => {
  return (
    <StyledSection padding={'4rem 1.5rem'}>
      <StyledTitle tag={tag} title={title} />
      <Content>
        <BoxLeft>
          <MainParagraph variant="copy1">{mainDescription}</MainParagraph>
        </BoxLeft>
        <Box>
          {descriptions.map(({ description_paragraph }, index: number) => {
            return (
              <Paragraphs mb="1rem" key={index} variant="copy2">
                {description_paragraph}
              </Paragraphs>
            );
          })}
        </Box>
      </Content>
    </StyledSection>
  );
};

export default Description;
