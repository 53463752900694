import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Dropdown from '../components/insightsDropdown';
import Seo from '../components/seo';
import Section from '../components/section';
import ToolCard from '../components/toolCard';
import Description from '../components/sections/ToolsSection/Description';
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';
import patternCover from '../assets/images/workbench_pattern.svg';
import {
  PricingContext,
  getLowestToolPriceInWorkbench,
  currencySymbols,
  getCurrencyName,
} from '../providers/PricingProvider';
import { Typography } from '@deeptrue-frontend/ui';

type WorkbenchTemplateProps = {
  pageContext: {
    language: string;
    workbench_slug: string;
  };
  data: {
    allPrismicWorkbenches: any;
    allPrismicCommonInformation: any;
  };
};

const StyledTypography = styled(Typography)`
  margin: 2rem 0;
`;

// const StyledCard = styled(ToolCard)`
//   .m-feature__content {
//     margin-bottom: 9rem;
//   }
// `;

const WorkbenchTemplate = ({ pageContext, data }: WorkbenchTemplateProps) => {
  const intl = useIntl();
  const [insight, setInsight] = useState(null);
  const { allPrismicWorkbenches, allPrismicCommonInformation } = data;

  const { language, workbench_slug } = pageContext;
  const workbenches = allPrismicWorkbenches.nodes[0].data.body.map(
    (workbenchContent) => ({
      ...workbenchContent.primary,
      items: workbenchContent.items,
    })
  );
  const currentWorkbench = workbenches.filter(
    (workbench) => workbench.workbench_slug === workbench_slug
  )[0];
  const commonData = allPrismicCommonInformation.edges[0].node.data;

  if (!currentWorkbench) return null;

  const {
    tools_title,
    tools_headline,
    workbench_description,
    workbench_name,
    workbench_cover,
    case_tagline,
    case_title,
    case_main_description,
    case_description1,
    case_description2,
    items: products,
  } = currentWorkbench;

  const options = currentWorkbench.items.map(({ tool }) => {
    return {
      value: tool.slug,
      text: tool.document.data.title,
    };
  });

  return (
    <PricingContext.Consumer>
      {({ pricingData, toolsPricing }) => {
        if (!toolsPricing || !pricingData) return null;
        return (
          <Layout lang={language}>
            <Seo title={workbench_name} lang={language} />
            <Breadcrumb className="breadcrumb--workbench">
              <BreadcrumbAnchor
                label={intl.formatMessage({
                  id: 'home',
                  defaultMessage: 'home',
                })}
                url="/"
              />
              <BreadcrumbDivider />
              <BreadcrumbAnchor active label={workbench_name} />
            </Breadcrumb>
            <Section className="hero hero--workbench">
              <div className="hero__wrap">
                <div className="hero__content">
                  <h1 className="hero__title">{workbench_name}</h1>
                  <p className="hero__description">{workbench_description}</p>
                  <StyledTypography variant={'h4'}>
                    {commonData.pricing_starting_at_text}{' '}
                    {Math.round(
                      getLowestToolPriceInWorkbench(
                        toolsPricing,
                        workbench_name
                      ) *
                        pricingData.currencyRates[
                          getCurrencyName(pricingData.currentCurrency)
                        ]
                    )}{' '}
                    {
                      currencySymbols[
                        getCurrencyName(pricingData.currentCurrency)
                      ]
                    }
                  </StyledTypography>

                  {options && options.length > 0 && (
                    <form className="hero__form">
                      <Dropdown
                        className="hero__dropdown"
                        // eslint-disable-next-line no-undef
                        onChange={(value: any) => setInsight(value)}
                        placeholder={intl.formatMessage({
                          id: 'index.hero.placeholder',
                        })}
                        options={options}
                      />

                      <Link
                        to={`/brief?briefing=${
                          insight ? insight : currentWorkbench.items[0].slug
                        }`}
                        className="hero__button"
                      >
                        <FormattedHTMLMessage id="index.hero.cta_button" />
                      </Link>
                    </form>
                  )}
                </div>
                <div className="hero__cover">
                  <embed
                    src={patternCover}
                    type="image/svg+xml"
                    className="hero__cover-pattern hero__cover-pattern--before"
                  />
                  <embed
                    src={patternCover}
                    type="image/svg+xml"
                    className="hero__cover-pattern hero__cover-pattern--after"
                  />
                  <img src={workbench_cover.url} alt={workbench_name} />
                </div>
              </div>
            </Section>
            <Description
              tag={case_tagline}
              title={case_title}
              mainDescription={case_main_description}
              descriptions={[
                {
                  description_paragraph: case_description1,
                },
                {
                  description_paragraph: case_description2,
                },
              ]}
            />
            <Section
              tagline={tools_title}
              title={tools_headline}
              className="workbench-page__tools"
            >
              {products.length === 0 && (
                <div className="workbench-page__no-data">
                  <h3 className="workbench-page__no-data-title">
                    {intl.formatMessage({
                      id: `wip`,
                    })}
                    ...
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: `wip_desc`,
                    })}
                  </p>
                </div>
              )}
              {products &&
                products.length > 0 &&
                products.map((product: any) => {
                  const { tool, tool_main_description } = product;
                  const { tool_django_slug } = tool.document
                    ? tool.document.data
                    : '';
                  return (
                    <ToolCard
                      key={`${tool.slug}`}
                      className="workbench-page__tool"
                      title={tool.document.data.title}
                      description={tool_main_description}
                      cta_label={`${intl.formatMessage({
                        id: 'index.tools.innovation.cta_text',
                      })}`}
                      cta_url={`/products/${tool_django_slug}`}
                      button_label={`${intl.formatMessage({
                        id: 'index.tools.innovation.button_text',
                      })}`}
                      button_url={`/${intl.locale}/brief/?briefing=${tool_django_slug}`}
                      lang={intl.locale}
                      price={Math.round(
                        toolsPricing[tool_django_slug].price *
                          pricingData.currencyRates[
                            getCurrencyName(pricingData.currentCurrency)
                          ]
                      )}
                      currency={
                        currencySymbols[
                          getCurrencyName(pricingData.currentCurrency)
                        ]
                      }
                      isFree={toolsPricing[tool_django_slug].is_free}
                      starting_at_text={commonData.pricing_starting_at_text}
                    />
                  );
                })}
            </Section>
          </Layout>
        );
      }}
    </PricingContext.Consumer>
  );
};

export const myQuery = graphql`
  query Workbenches($prismicLang: String) {
    allPrismicWorkbenches(filter: { lang: { eq: $prismicLang } }) {
      nodes {
        _previewable
        lang
        data {
          body {
            ... on PrismicWorkbenchesDataBodyWorkbench {
              id
              primary {
                workbench_slug
                workbench_name
                workbench_icon {
                  url
                }
                workbench_description
                workbench_cover {
                  url
                }
                case_tagline
                case_title
                case_main_description
                case_description1
                case_description2
                tools_title
                tools_headline
              }
              items {
                tool_main_description
                is_new
                is_prod
                tool {
                  slug
                  document {
                    ... on PrismicToolDetail {
                      data {
                        title
                        tool_django_slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCommonInformation(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          lang
          data {
            pricing_per_tool_text
            pricing_starting_at_text
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(WorkbenchTemplate);
