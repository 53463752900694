import React from 'react';
import styled from 'styled-components';

const SectionStyled = styled.section<{ fullWidth: boolean }>`
  width: 100%;
  display: flex;
  max-width: calc(100% - 3rem);
  margin-left: 1.5rem;
  maring-right: 1.5rem;
  margin-bottom: 6.5rem;
  flex-direction: column;

  @media (min-width: 960px) {
    margin-bottom: 8rem;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    background-color: #FAFAFA;
    margin-left: 0;
    maring-right:0;
    max-width: unset !important;
  `}
`;

interface Props {
  children: any;
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  fullWidth?: boolean;
  padding?: string;
  bgColor?: string;
  id?: string;
  className?: string;
}
const Section = ({
  children,
  alignItems = 'flex-start',
  fullWidth = false,
  padding = '0',
  bgColor,
  id,
  className,
}: Props) => {
  return (
    <SectionStyled
      id={id}
      fullWidth={fullWidth}
      style={{
        alignItems: alignItems,
        padding: padding,
        backgroundColor: bgColor ? bgColor : fullWidth ? '#FAFAFA' : 'white',
      }}
      className={className}
    >
      {children}
    </SectionStyled>
  );
};

export default Section;
