import React from 'react';
import styled from 'styled-components';
import { Typography } from '@deeptrue-frontend/ui';

interface Props {
  tag?: string;
  title: string;
  description?: string;
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  noLine?: boolean;
  titleColor?: string;
  tagColor?: string;
  className?: string;
}

const TitleText = styled(Typography)<{ noLine: boolean }>`
  font-size: 2rem;
  font-weight: 500;
  position: relative;
  text-align: center;
  @media (min-width: 960px) {
    &::after {
      content: '';
      width: 100vw;
      height: 1px;
      background-color: #e6e6e5;
      display: ${({ noLine }) => (noLine ? 'none' : 'block')};
      position: absolute;
      left: 100%;
      top: 1.5rem;
    }
  }
`;
const Tag = styled(Typography)`
  color: #5485f8;
  font-size: 1rem;
  font-weight: 600;
`;
const Description = styled(Typography)`
  color: #5c6370;
  font-size: 1rem;
  font-weight: 400;
`;

const TitleWrapper = styled.div<{ alignItems: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};

  @media (min-width: 960px) {
    width: 40%;
  }
`;

const Title = ({
  tag,
  title,
  description,
  alignItems = 'flex-start',
  noLine = false,
  titleColor,
  tagColor,
  className,
}: Props) => {
  return (
    <TitleWrapper alignItems={alignItems} className={className}>
      {tag && (
        <Tag color={tagColor || '#5485f8'} variant="copy1">
          {tag}
        </Tag>
      )}
      <TitleText noLine={noLine} variant="h6" color={titleColor || '#353531'}>
        {title}
      </TitleText>
      {description && <Description variant="copy1">{description}</Description>}
    </TitleWrapper>
  );
};

export default Title;
