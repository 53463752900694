import React from 'react';
import styled from 'styled-components';
import { Icon, iconVariants } from '@deeptrue-frontend/ui';
import TagIcon from '../../assets/icons/TagIcon.svg';

type Variants = typeof iconVariants[number];

type CardProps = {
  title: any;
  description: any;
  cta_url?: any;
  cta_label?: any;
  button_url?: any;
  button_label?: any;
  iconVariant?: Variants;
  iconClass?: string;
  className?: string;
  onClick?: () => void;
  lang: string;
  price?: number;
  currency?: string;
  isFree?: boolean;
  starting_at_text?: string;
};

const StyledParagraph1 = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
`;

const StyledParagraph2 = styled.p`
  font-size: 2rem;
  font-weight: 500;
`;

const StyledArticle = styled.article`
  .m-feature {
    &__content {
      margin-bottom: 12rem;
    }
    &__cta {
      bottom: 2rem;
      display: flex;
    }
    &__button-link {
      width: fit-content;
    }
  }
`;

const ToolCard = ({
  title,
  description,
  cta_url,
  cta_label,
  button_url,
  button_label,
  iconVariant,
  iconClass,
  className,
  onClick,
  lang,
  price,
  currency,
  isFree,
  starting_at_text,
}: CardProps) => {
  return (
    <StyledArticle className={`m-feature ${className ? className : ''}`}>
      {iconVariant && (
        <div className="m-feature__icon">
          <Icon className={iconClass || ''} variant={iconVariant} />
        </div>
      )}
      <div
        style={{ paddingBottom: '1rem' }}
        className={`m-feature__content ${
          button_label && cta_label ? 'm-feature__content--full' : ''
        }`}
      >
        <h3 className="m-feature__title">{title}</h3>
        <p>{description}</p>
        <div className="m-feature__price">
          <StyledParagraph1>{starting_at_text}</StyledParagraph1>
          {!isFree && (
            <StyledParagraph2>
              {price} {currency}
            </StyledParagraph2>
          )}
          {isFree && (
            <StyledParagraph2>
              <span
                style={{ textDecoration: 'line-through', color: '#5C6370' }}
              >
                {price}
              </span>{' '}
              {' 0 '}
              {currency} <img src={TagIcon} />
            </StyledParagraph2>
          )}
        </div>
        <div className="m-feature__cta">
          <a
            onClick={onClick}
            className="m-feature__cta-link"
            href={`/${lang}${cta_url}`}
            hrefLang={lang}
          >
            {cta_label} <Icon className="m-feature__arrow" variant="arrow" />
          </a>
          {button_label && (
            <a href={button_url} className="m-feature__button-link">
              {button_label}
            </a>
          )}
        </div>
      </div>
    </StyledArticle>
  );
};

export default ToolCard;
